.logo-center {
  background-color: #fff;
  width: 172px;
  height: 170px;
}
.text-portal {
  color: #86939e;
}
.bcgr-form {
  border: 1px solid #f0f0f0;
  background-color: #f8f5f3;
  border-radius: 10px;
}
.text-title {
  color: #326267;
  font-size: 30px !important;
  font-weight: 500;
  text-align: center;
}
#login-form .form-control {
  color: #43484d;
  font-family: "D-DIN";
  border-bottom: 2px solid #141b2d;
  background-color: transparent;
  border-left: 0px;
  border-right: 0px;
  border-top: 0px;
  border-radius: 0px;
  padding: 0px 0px 10px 0px;
  font-size: 14px;
}
#login-form .form-control:focus {
  color: #43484d;
  background-color: transparent;
  border-bottom: 2px solid #326267;
  outline: 0;
  box-shadow: none;
}
#login-form .btn-primary {
  font-family: "D-DIN";
  background-color: #727681 !important;
  color: black !important;
  border-color: #727681 !important;
}
#login-form .btn-primary:focus,
.btn-primary:hover {
  box-shadow: none !important;
  opacity: 0.8;
  color: #fff;
  background: #727681;
}
/*********** BOTONES RECUPERAR CONTRASENA INPUT Y SELECT****************/
#form-recuperar .form-control {
  color: #43484d;
  font-family: "D-DIN";
  border-bottom: 2px solid #141b2d;
  background-color: transparent;
  border-left: 0px;
  border-right: 0px;
  border-top: 0px;
  border-radius: 0px;
  padding: 0px 0px 10px 0px;
  font-size: 14px;
}
#form-recuperar .form-control:focus {
  color: #43484d;
  background-color: transparent;
  border-bottom: 2px solid #326267;
  outline: 0;
  box-shadow: none;
}
#form-recuperar .btn-primary {
  font-family: "D-DIN";
  background-color: #727681 !important;
  color: black !important;
  border-color: #727681 !important;
}
#form-recuperar .btn-primary:focus,
.btn-primary:hover {
  box-shadow: none !important;
  opacity: 0.8;
  color: #fff;
  background: #727681;
}

.input-group-text {
  background-color: #fff;
  color: #e1e8ee;
}
.form-select {
  color: #86939e !important;
  font-family: D-DIN;
  font-size: 14px !important;
}
.form-select:focus {
  border-color: #727681;
  box-shadow: none !important;
  outline: 0;
}
.form-control {
  color: #86939e !important;
  font-family: D-DIN;
  font-size: 14px !important;
}
.form-control:focus {
  border-color: #727681 !important;
  box-shadow: none !important;
  outline: 0;
}
/**************************/
.link-ct {
  color: #5c5d60;
  text-decoration: none;
  font-family: "D-DIN";
}
.link-ct:hover {
  text-decoration: none;
  color: #3f4043;
  border-bottom: 2px solid #326267;
}
#login-form .btn-secondary {
  background-color: #326267;
  font-family: "D-DIN";
}
#login-form .btn-secondary:hover,
#login-form .btn-secondary:focus {
  background-color: #4c787c;
  color: white !important;
  box-shadow: none;
}
.brg-footer {
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: transparent;
}
ul li {
  list-style: none;
}
ul.redes-pv li {
  display: inline-block;
  font-size: 26px;
  color: #727681;
  margin-left: 5px;
  margin-right: 5px;
  font-weight: 300;
}
.brg-footer p {
  color: #86939e;
}
.brg-footer a {
  color: #86939e;
  text-decoration: none;
}
.brg-footer a:hover {
  color: #727681;
}

/********************************
********* BOARD STYLES **********
*********************************/
.logo-admin {
  width: 90px;
}
.sidebar-brand {
  font-weight: 600;
  font-size: 1.15rem;
  padding: 22px !important;
  display: block;
  color: #a2a2a2;
  border-bottom: 1px solid #e4e8ed;
}
.sidebar-header {
  background: transparent;
  padding: 12px 12px 12px 25px !important;
  font-size: 21px !important;
  color: #a2a2a2 !important;
  border-bottom: 1px solid #e4e8ed;
  font-family: "D-DIN-Condensed-Bold";
}
.sidebar-item {
  color: #a2a2a2 !important;
  border-bottom: 1px solid #e4e8ed;
  font-size: 13px;
}
.sub-sidebar-item {
  border-top: 1px solid #e4e8ed;
}
.sidebar-link,
a.sidebar-link {
  color: #a2a2a2 !important;
  font-family: "D-DIN-DINExp";
  background-color: #fff !important;
}
.sidebar {
  background-color: #fff !important;
  border-right: 1px solid #e4e8ed;
}
.sidebar-content {
  background-color: #fff !important;
}
.sidebar-link i,
.sidebar-link svg,
a.sidebar-link i,
a.sidebar-link svg {
  margin-right: 0.75rem;
  color: #727681 !important;
}
.sidebar-link:hover {
  background-color: #f8f9fa !important;
}
.main {
  background-color: #f6f6f6 !important;
}
.hamburger,
.hamburger:before,
.hamburger:after {
  background-color: #727681 !important;
}
.text-head {
  color: #5d5e60;
  font-size: 16px;
  font-family: "D-DIN";
}
.yellow-pv {
  color: #727681 !important;
  font-family: "D-DIN-DINExp";
}
.footer-board {
  border-top: 1px solid #e4e8ed;
}
.text-muted {
  font-family: "D-DIN";
}
.swal2-styled.swal2-confirm {
  background-color: #727681 !important;
}
.dorder-btm {
  border-bottom: 1px solid #e4e8ed;
}
.breadcrumb-item a {
  color: #5d5e60;
  font-family: "D-DIN";
  font-size: 13px;
}
.breadcrumb-item a:hover {
  color: #727681;
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #326267 !important;
  font-size: 13px;
  font-family: "D-DIN-DINExp";
}
.text-green2 {
  color: #3e627c;
  font-family: "D-DIN-Condensed-Bold";
  font-size: 15px;
  background-color: #e1e8ee;
}

.text-green {
  color: #3e627c;
  font-family: "D-DIN-Condensed-Bold";
  font-size: 15px;
}
.datos-user {
  color: #86939e;
  font-family: "D-DIN";
  font-size: 15px;
}
.btn-pv {
  background-color: #727681 !important;
  border-color: #727681 !important;
  border-radius: 32px !important;
  color: #fff !important;
  font-family: "D-DIN-Condensed-Bold";
  font-size: 15px !important;
}
.btn-pv:hover {
  background-color: #fff !important;
  border-color: #727681 !important;
  color: #727681 !important;
}
.btn-check:focus + .btn-pv,
.btn-pv:focus {
  background-color: #fff;
  border-color: #fff;
  color: #727681;
}

.style-ico {
  font-size: 23px;
  color: #727681;
  text-decoration: none;
}
.page-link {
  background-color: transparent !important;
  border: 0px !important;
  font-size: 16px !important;
}
.page-link.active,
.active > .page-link {
  background-color: #727681 !important;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  text-align: center;
}
.page-item.disabled .page-link {
  color: #575757;
  background-color: transparent !important;
  border: 0px !important;
}

.page-link:focus {
  box-shadow: none !important;
}
.dataTables_info {
  color: #ababab;
  font-size: 16px;
  font-family: "D-DIN-DINExp";
}
.text-modal {
  font-family: "D-DIN-DINExp";
  font-size: 17px;
  color: #ababab;
}
.modal-title {
  font-family: "D-DIN-DINExp";
  color: #3e627c !important;
}
.modal-header {
  background-color: #e1e8ee;
}

.btn.btn-secondary {
  border-radius: 32px;
  background-color: #bcbec0;
  font-size: 15px;
  border: 1px solid #bcbec0;
  box-shadow: none !important;
  line-height: 1.3;
}
.btn.btn-secondary:hover {
  background-color: #fff;
  color: #bcbec0 !important;
  border: 1px solid #bcbec0;
}
.btn.btn-secondary:focus:before {
  background-color: #fff !important;
  color: #bcbec0 !important;
  border: 1px solid #bcbec0 !important;
}
.btn.btn-secondary:active {
  background-color: #fff !important;
  color: #bcbec0 !important;
  border: 1px solid #bcbec0 !important;
}

.btn.btn-success {
  color: #fff !important;
  border-radius: 32px;
  background-color: #727681;
  font-size: 15px;
  border: 1px solid #727681 !important;
  box-shadow: none !important;
  line-height: 1.3;
}
.btn.btn-success:hover {
  color: #727681 !important;
  background-color: #fff !important;
}
.btn.btn-success:focus:before {
  color: #727681;
  background-color: #fff;
}

.form-check-input:focus {
  border-color: #727681;
  box-shadow: 0 0 0 0.2rem #f1b80e40;
  outline: 0;
}

.form-check-input:checked {
  background-color: #727681;
  border-color: #727681;
}
